var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"predictions"},[_c('section-title',{attrs:{"title":"Prediction list","link":"prediction-add"}}),(_vm.items && _vm.items.length)?_c('v-card',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","footer-props":{
              'items-per-page-options': [10, 25, 50, 100],
            }},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
            var item = ref.item;
return [_c('img',{attrs:{"src":item.image}})]}},{key:"item.resultText",fn:function(ref){
            var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.resultText)}})]}},{key:"item.isEnded2",fn:function(ref){
            var item = ref.item;
return [(item.isEnded)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"secondary","disabled":""}},[_vm._v(" Ended ")]):_vm._e()]}},{key:"item.isEnded",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.publishItem(item.id)}}},[_vm._v(" Set result ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                    name: 'prediction-edit',
                    params: { id: item.id },
                  }}},[_c('v-btn',{attrs:{"size":"sm","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1)],1),_c('v-btn',{attrs:{"size":"sm","icon":""},on:{"click":function($event){return _vm.removeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete")])],1)]}}],null,true)})],1):_c('div',[_c('empty-alert')],1),_c('remove-dialog',{on:{"delete":_vm.removeItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.resultDialog),callback:function ($$v) {_vm.resultDialog=$$v},expression:"resultDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text",staticStyle:{"color":"white"}},[_vm._v(" Attention ")]),_c('v-card-text',[_c('p',{staticClass:"mt-5 mb-0"},[_vm._v(" Is the prediction successful? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary"},on:{"click":function($event){_vm.resultDialog=false}}},[_vm._v(" Cancel")]),_c('div',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.approveResult(false)}}},[_vm._v("No")]),_c('v-btn',{staticClass:"white--text ml-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveResult(true)}}},[_vm._v("Yes")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }